import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ContainerDefault } from 'components/containers';
import { RichTextField } from 'components/ui';
import { Image } from 'components/ui';

function Banner(props) {
  const { bannerColorTheme, bannerContent, bannerImage, isFront } = props;
  const spacer_value = process.env.DEFAULT_SPACER_VALUE;

  const bannerContentMarginTopLg = isFront ? 21 : 14;

  const bannerContentMarginTopMd = isFront ? 9 : 9;
  const bannerContentMarginTopXs = isFront ? 5 : 9;

  const bannerWrapperPb = isFront ? 10 : 0;

  const bannerWrapperMbMd = isFront
    ? parseInt(spacer_value / 2)
    : parseInt(spacer_value / 2);

  return (
    <Box
      pb={bannerWrapperPb}
      mb={[8, 8, bannerWrapperMbMd, bannerWrapperMbMd]}
      sx={{
        background: bannerColorTheme ? bannerColorTheme : 'background.default',
      }}
    >
      <ContainerDefault>
        <Grid
          container
          flexDirection="row-reverse"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={4} lg={6}>
            {bannerImage && (
              <Box
                sx={{
                  marginLeft: ['-24px', '-24px', '0px'],
                  width: [
                    'calc(100% + 48px)',
                    'calc(100% + 48px)',
                    'calc(100% + 6vw)',
                  ],
                }}
              >
                <Image src={bannerImage.url} alt={bannerImage.alt} />
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {bannerContent && (
              <Box
                className="banner-content-wrapper"
                mt={{
                  xs: bannerContentMarginTopXs,
                  md: bannerContentMarginTopMd,
                  lg: bannerContentMarginTopLg,
                }}
              >
                <RichTextField data={bannerContent} />
              </Box>
            )}
          </Grid>
        </Grid>
      </ContainerDefault>
    </Box>
  );
}

Banner.propTypes = {
  bannerColorTheme: PropTypes.string,
  bannerContent: PropTypes.array,
  bannerImage: PropTypes.object,
  isFront: PropTypes.bool,
};

export default Banner;
